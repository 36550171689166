import React from "react";

import { NextPage } from "next";
import dynamic from "next/dynamic";

import Page from "@/layouts/BasePage";

import type { SiteProps } from "utils/types";

const ErrorPage = dynamic(
  async () => (await import("ui/src/ErrorPage")).ErrorPage,
  { ssr: false }
);

const FourOhFour: NextPage<SiteProps> = (props) => {
  return (
    <Page
      metadata={{ title: "Page not found" }}
      hideFooter
      hideHeader
      hideBreadCrumbs
      {...props}
    >
      <ErrorPage
        heading="We can't find what you're looking for"
        message="This page has doesn't exist or is no longer available. We will be working
        to remove this link."
        buttonAction="back"
      />
    </Page>
  );
};

export default FourOhFour;

export async function getStaticProps() {
  const { fetchSiteProps } = await import("@/utils/fetchers");
  const siteProps = await fetchSiteProps();

  return { props: { ...siteProps } };
}
